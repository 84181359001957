import * as React from "react"
import { Layout } from "../components/Layout"
import { HomeKnowledge } from "../components/HomeKnowledge"
import { HomeTop } from "../components/HomeTop"
import { HomeContactUs } from "../components/HomeContactUs"
import { HomeOurGames } from "../components/HomeOurGames"
import { HomeCaseStudy } from "../components/HomeCaseStudy"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>OakGames</title>
        <meta
          name="description"
          content="OakGames - get amazing games for your company"
        />
      </Helmet>
      <HomeTop />
      <HomeKnowledge />
      <HomeOurGames
        smokefusionThumbnail={data.smokefusionThumbnail}
        astroflapThumbnail={data.astroflapThumbnail}
        foxrunnerThumbnail={data.foxrunnerThumbnail}
        aliendefenseThumbnail={data.aliendefenseThumbnail}
      />
      <HomeCaseStudy
        caseStudyAiThumbnail={data.caseStudyAiThumbnail}
        caseStudyGameDevThumbnail={data.caseStudyGameDevThumbnail}
      />
      <HomeContactUs />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    astroflapThumbnail: file(relativePath: { eq: "astroflap-thumbnail.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    smokefusionThumbnail: file(
      relativePath: { eq: "smokefusion-thumbnail.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    foxrunnerThumbnail: file(relativePath: { eq: "foxrunner-thumbnail.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    aliendefenseThumbnail: file(
      relativePath: { eq: "aliendefense-thumbnail.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    caseStudyAiThumbnail: file(
      relativePath: { eq: "case-study-ai-image.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    caseStudyGameDevThumbnail: file(
      relativePath: { eq: "case-study-game-dev-image.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
  }
`
