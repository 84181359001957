import styled from "styled-components"

export const Container = styled.div`
  margin: -190px auto 100px;
  backdrop-filter: blur(100px);
  border-radius: 40px 40px 0 0;
  border-top: #d7f2ff solid 2px;
  padding: 50px 8vw 0;
  @media (max-width: 890px) {
    margin: -40px auto 64px;
  }
`

export const Header = styled.h2`
  text-align: center;
  margin-bottom: 90px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(1400px + 8vw);
  margin: 0 auto;
  @media (max-width: 890px) {
    gap: 50px;
  }
`

export const Section = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 4%;
  @media (max-width: 890px) {
    display: flex;
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  &#virtual-reality {
    left: -28px;
    justify-self: start;
  }
   &#augmented-reality  {
    right: -65px;
    justify-self: end;
  }
  &#artificial-intelligence {
    justify-self: start;
  }
  &#unity-game-development {
    justify-self: end;
    left: -95px;
  }
  &#gamification-system {
    left: -28px;
    justify-self: start;
  }
  &#branding-games {
    justify-self: end;
  }

  @media (max-width: 890px) {
    position: static;
  }
`

export const Text = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-top: 80px;
  @media (max-width: 890px) {
    padding-top: 0;
  }
`

export const HorizontalGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: 20px 0 90px;
  max-width: calc(1400px + 8vw);
  margin: 0 auto;
  @media (max-width: 890px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    /* padding-top: 0; */
  }
`

export const IconWrapper = styled.div`
  height: 100%;
  @media (max-width: 890px) {
    width: 100px;
    padding-bottom: 16px;
  }
`

export const Item = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  max-width: 250px;
  @media (max-width: 890px) {
    align-items: center;
    padding-top: 0;
  }
`

export const SmallHeader = styled.h4`
  @media (max-width: 890px) {
    text-align: center;
  }
`
