import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, ImageWrapper, TextWrapper, Button } from "./style"

export const HomeOurGamesElement = ({
  title,
  description,
  slug,
  thumbnail,
  href,
}) => {
  return (
    <Container>
      <ImageWrapper>
        <GatsbyImage
          image={getImage(thumbnail)}
          alt="alt"
          objectFit="contain"
          placeholder="blurred"
          style={{ width: "100%", height: "100%" }}
          imgStyle={{ width: "100%" }}
        />
      </ImageWrapper>
      <TextWrapper>
        <h3>{title}</h3>
        <p>{description}</p>
        <Button
          href={slug !== "aliendefense" ? `/games/${slug}/` : `${href}`}
          target="_blank"
        >
          PLAY GAME
        </Button>
      </TextWrapper>
    </Container>
  )
}
