import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {
  Container,
  Content,
  Section,
  ImageWrapper,
  Text,
  Header,
  HorizontalGroup,
  IconWrapper,
  Item,
  SmallHeader,
} from "./style"

export const HomeKnowledge = () => {
  return (
    <Container>
      <HorizontalGroup>
        <Item>
          <IconWrapper>
            <StaticImage
              src="../../images/prototyping-image.png"
              alt="icon"
              placeholder="blurred"
              quality="100"
              style={{ height: "100%" }}
            />
          </IconWrapper>
          <SmallHeader>Prototyping & programming in Unity</SmallHeader>
          <p>
            We specialize in creating games and products with gamification
            mechanisms that set new trends in the industry
          </p>
        </Item>
        <Item>
          <IconWrapper>
            <StaticImage
              src="../../images/ux-image.png"
              alt="icon"
              placeholder="blurred"
              quality="100"
              style={{ height: "100%" }}
            />
          </IconWrapper>
          <SmallHeader>UX/UI Design</SmallHeader>
          <p>
            Everything we do must be user-friendly and intuitive. Our ux/ui
            design team works on it every day
          </p>
        </Item>
        <Item>
          <IconWrapper>
            <StaticImage
              src="../../images/augmented-image.png"
              alt="icon"
              placeholder="blurred"
              quality="100"
              style={{ height: "100%" }}
            />
          </IconWrapper>
          <SmallHeader>Augmented reality</SmallHeader>
          <p>
            Our customizable Augmented Reality products will help you build your
            brand awareness
          </p>
        </Item>
      </HorizontalGroup>
      <Header>Knowledge and skills</Header>
      <Content>
        <Section>
          <ImageWrapper id="virtual-reality">
            <StaticImage
              src="../../images/virtual-reality-image.png"
              alt="human"
              placeholder="blurred"
              quality="100"
            />
          </ImageWrapper>
          <Text>
            <h3>Virtual Reality</h3>
            <p>
              With VR, <b>games</b> become more interactive and their worlds are more immersive and sensory. 
              Virtual games also make it possible to feel emotions such as fear, excitement, or joy, 
              which are intensified by the immersiveness of VR.
            </p>
            <p>
              <b>Simulators</b> have become an increasingly popular training 
              technology because they offer realistic experiences that allow 
              people to practice skills without risk of harm or other health risks. 
              In addition, VR simulators are able to provide consistent and repeatable training conditions, 
              which is especially important for safety training or machine work.
            </p>
          </Text>
        </Section>

        <Section reverse>
          <Text>
            <h3>Augmented Reality</h3>
            <p>
             Technology that allows the introduction of virtual elements into reality. 
             Particularly useful for creating virtual prototypes, accident scenarios, 
             visualizing instruction manuals, 
             and even enhancing the appeal of virtual tours
            </p>
          </Text>
          <ImageWrapper id="augmented-reality">
            <StaticImage
              src="../../images/augmented-reality-image.png"
              alt="human"
              placeholder="blurred"
            />
          </ImageWrapper>
        </Section>

        <Section>
          <ImageWrapper id="gamification-system">
            <StaticImage
              src="../../images/ai-image.png"
              alt="human"
              placeholder="blurred"
              quality="100"
            />
          </ImageWrapper>
          <Text>
            <h3>Artificial Intelligence</h3>
            <p>
              As the world moves towards a more digital future, 
              there are still many problems to be solved. 
              With artificial intelligence and machine learning, 
              we are using technology to elevate entertainment and increase imersion.
            </p>
          </Text>
        </Section>

        <Section reverse>
          <Text>
            <h3>Unity Game Development</h3>
            <p>
             We develop engaging games in Unity technology. 
             Our team has complementary skills that allow us to move smoothly 
             through all stages of game development. 
             Engage new players with our professional services!
            </p>
          </Text>
          <ImageWrapper id="branding-games">
            <StaticImage
              src="../../images/unity-game-development-image.png"
              alt="human"
              placeholder="blurred"
            />
          </ImageWrapper>
        </Section>

        <Section>
          <ImageWrapper id="gamification-system">
            <StaticImage
              src="../../images/gamification-system-image.png"
              alt="human"
              placeholder="blurred"
              quality="100"
            />
          </ImageWrapper>
          <Text>
            <h3>Gamification System</h3>
            <p>
              Get ready to amaze your customers and get more from your employees with gamification. 
              This solution is perfect for anyone who wants to increase engagement and drive innovation. 
              Your team will have fun, gain insights, 
              and make connections you never thought possible.
            </p>
          </Text>
        </Section>

        <Section reverse>
          <Text>
            <h3>Branding Games</h3>
            <p>
              We design, create and deliver immersive branding games 
              that support your company awareness. Tailored to your brand, 
              our games run seamlessly on desktop and mobile devices, 
              providing the best experience for you and your customers.
            </p>
          </Text>
          <ImageWrapper id="artificial-intelligence">
            <StaticImage
              src="../../images/branding-games-image.png"
              alt="human"
              placeholder="blurred"
              style={{ marginBottom: "40px" }}
            />
          </ImageWrapper>
        </Section>
      </Content>
    </Container>
  )
}
