import styled from "styled-components"

export const Container = styled.div`
  margin-top: -40px;
  width: 100%;
  border-top: #f8f8f8 solid 2px;
  border-radius: 40px 40px 0 0;
  backdrop-filter: blur(50px);
`

export const Content = styled.div`
  margin: 0 auto 100px;
  max-width: calc(1310px + 8vw);
  padding: 80px 8vw 0;
  @media (max-width: 890px) {
    margin: 0 auto 60px;
    padding: 30px 8vw 0;
  }
`

export const Header = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`

export const SliderWrapper = styled.div`
  margin: 0 auto;
  @media (max-width: 890px) {
    max-width: 82vw;
  }
`
