import React from "react"
import {
  Container,
  LogoWrapper,
  ContactButton,
  Content,
  Left,
  Buttons,
  Right,
} from "./style"
import { Button } from "../Button"
import { StaticImage } from "gatsby-plugin-image"

export const HomeTop = () => {
  return (
    <Container>
      <LogoWrapper to="/">
        <StaticImage
          src="../../images/oak-games-logo.png"
          alt="human"
          placeholder="blurred"
          quality="100"
        />
      </LogoWrapper>
      <LogoWrapper to="/" mobile={+true}>
        <StaticImage
          src="../../images/oak-games-logo-mobile.png"
          alt="human"
          placeholder="blurred"
          quality="100"
        />
      </LogoWrapper>
      <Content>
        <Left>
          <h1>Joyful reality</h1>
          <Buttons>
            <Button
              text="TRY GAME"
              black={+true}
              shadow={+true}
              to="/#our-games"
            />
            <Button text="CASE STUDIES" to="/#case-studies" />
          </Buttons>
        </Left>
        <Right>
          <StaticImage
            src="../../images/gamepad-image.png"
            alt="human"
            placeholder="blurred"
            quality="100"
            style={{ height: "100%" }}
          />
        </Right>
        <ContactButton>
          <Button text="CONTACT" black={+true} shadow={+true} to="/#contact" />
        </ContactButton>
      </Content>
    </Container>
  )
}
