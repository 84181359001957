import styled from "styled-components"
import imageDesktop from "../../images/contact-us-background.jpg"
import imageMobile from "../../images/contact-us-background-mobile.jpg"

export const Container = styled.div`
  margin: 0 auto;
  @media (max-width: 890px) {
    margin: 0 auto;
    padding: 0;
  }
`

export const Content = styled.div`
  background-image: url(${imageDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  grid-template-areas: ". form";
  padding: 90px 0;
  display: grid;
  grid-template-columns: 60% 40%;
  width: 100%;
  min-height: 100vh;
  border-radius: 40px 40px 0 0;
  border: none;
  @media (max-width: 890px) {
    background-size: contain;
    background-image: url(${imageMobile});
    display: flex;
    padding: 400px 0 50px;
    align-items: center;
    gap: 72px;
    flex-direction: column;
  }
`

export const Input = styled.input`
  font-size: 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  padding-bottom: 16px;
  background: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #212529;
    opacity: 0.5;
  }
`

export const TextArea = styled.textarea`
  font-size: 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  resize: vertical;
  font-family: "Open sans" Sans-serif;
  background: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #212529;
    opacity: 0.5;
  }
`

export const Label = styled.label`
  font-size: 12px;
  display: block;
  padding-bottom: 8px;
`

export const Error = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  color: #d20808;
  font-size: 0.8em;
  margin-top: 0.7em;
`

export const Left = styled.div`
  position: relative;
`

export const Form = styled.form`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  grid-area: form;
  padding: 40px;
  max-width: 500px;
  margin-bottom: 200px;
  background: linear-gradient(#93999f, #93999f) padding-box,
    linear-gradient(#ffffff, #ffffff00) border-box;
  border: 2px solid transparent;
  border-radius: 40px;
  @media (max-width: 890px) {
    background: none;
    border: 2px solid #ffffff80;
    background-color: #f8f8f880;
    padding-bottom: 80px;
    border-radius: 40px 40px 0 0;
    width: 100%;
    backdrop-filter: blur(50px);
    & p {
      text-align: left;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  @media (max-width: 890px) {
    justify-content: center;
  }
`

export const ButtonWrapper = styled.button`
  padding: 14px 25px;
  border-radius: 60px;
  color: #fff;
  background-color: #232125;
  @media (max-width: 890px) {
    align-self: stretch;
  }
`

export const ButtonText = styled.h5`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: #fff;
`
