import styled from "styled-components"

export const Container = styled.div`
  background-image: linear-gradient(120deg, #d7f2ff 30%, #a1c4fd);
  padding: 50px 0 110px;
  border-radius: 40px 40px 0 0;
  border-top: #d7f2ff solid 2px;
  @media (max-width: 890px) {
    padding: 30px 0 120px;
    margin: 0 auto 0px;
  }
`

export const Header = styled.h2`
  margin-bottom: 10px;
  text-align: center;
`

export const SliderWrapper = styled.div`
  margin: 0 auto;
  @media (max-width: 890px) {
    max-width: 82vw;
  }
`
