import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container, Header, SliderWrapper } from "./style"
import { HomeOurGamesElement } from "../HomeOurGamesElement"

export const HomeOurGames = ({
  smokefusionThumbnail,
  astroflapThumbnail,
  foxrunnerThumbnail,
  aliendefenseThumbnail,
}) => {
  const astroflapInfo = {
    title: "AstroFlap",
    description:
      "AstroFlap is a classic arcade game in which the player helps an astronaut fly through space. There is also an option to install this game in your theme park, along with the use of a camera to record the player's movement. An ideal game for children of all ages. It's a great way to extend the time your visitors spend in the park. Let them experience a whole new kind of adventure and challenge them with our app!",
    thumbnail: astroflapThumbnail,
    slug: "astroflap",
  }

  const smokefusionInfo = {
    title: "SmokeFusion",
    description:
      "Smoke Fusion is a game that takes place on interactive screens placed around the park. It will make your amusement park more enjoyable for children while encouraging them to be more active. An ideal game for children of all ages. It's a great way to extend the time your visitors spend in the park. Let them experience a whole new kind of adventure and challenge them with our app!",
    thumbnail: smokefusionThumbnail,
    slug: "smokefusion",
  }

  const foxrunnerInfo = {
    title: "Fox Runner",
    description:
      "Fox runner is a platform game with 3D graphics, where the player's goal is to cover as much distance as possible. During the gameplay, the player must avoid obstacles and collect extra time by jumping over gates. The game engine generates a new route with each playthrough, making the game a completely new experience each time!",
    thumbnail: foxrunnerThumbnail,
    slug: "foxrunner",
  }

  const aliendefenseInfo = {
    title: "Alien Defense VR",
    description:
      "The gameplay in Alien Defense aims to maintain the classic physicality of shooting in an arcade game. A Science Fiction themed game in which you travel through the galaxy, fight ruthless enemies and uncover dark secrets to save all of humanity. With a fascinating story and exciting missions, this game will draw you in from the very beginning.",
    thumbnail: aliendefenseThumbnail,
    slug: "aliendefense",
    href: "https://www.oculus.com/experiences/quest/7935548316518766/",
  }

  const ourGames = [
    astroflapInfo,
    smokefusionInfo,
    foxrunnerInfo,
    aliendefenseInfo,
  ]
  var settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Container id="our-games">
      <Header>Our games</Header>
      <SliderWrapper>
        <Slider {...settings}>
          {ourGames.map((game, index) => (
            <HomeOurGamesElement
              key={index}
              thumbnail={game.thumbnail}
              title={game.title}
              description={game.description}
              slug={game.slug}
              href={game?.href}
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Container>
  )
}
