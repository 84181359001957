import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

const ReCaptcha = ({ onChange }) => {
  return (
    <ReCAPTCHA
      sitekey="6Lfb2aEgAAAAAHBPLGcXDDQFf1HNQKCVnP4EcRuG"
      onChange={() => onChange(true)}
      onExpired={() => onChange(false)}
    />
  )
}

export default ReCaptcha
