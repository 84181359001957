import React from "react"
import { MessageContainer, Message, SecondMessage } from "./style"

const ContactMessage = ({ success }) => {
  if (success) {
    return (
      <MessageContainer>
        <Message>Message sent successfuly</Message>
        <SecondMessage success={success}>Thank you!</SecondMessage>
      </MessageContainer>
    )
  } else {
    return (
      <MessageContainer>
        <Message>There was an error, try again later...</Message>
        <SecondMessage success={success}>We're sorry</SecondMessage>
      </MessageContainer>
    )
  }
}

export default ContactMessage
