import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Container, ImageWrapper, Description } from "./style"

export const HomeCaseStudyElement = ({ title, description, thumbnail }) => {
  return (
    <Container>
      <ImageWrapper>
        <GatsbyImage
          image={getImage(thumbnail)}
          alt="alt"
          quality="100"
          placeholder="blurred"
        />
      </ImageWrapper>
      <h3>{title}</h3>
      <Description>{description}</Description>
    </Container>
  )
}
