import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container, Header, SliderWrapper, Content } from "./style"
import { HomeCaseStudyElement } from "../HomeCaseStudyElement"

export const HomeCaseStudy = ({
  caseStudyGameDevThumbnail,
  caseStudyAiThumbnail,
}) => {
  const aiCamera = {
    title: "AI camera based motion tracking",
    description:
      "Our client was in need for camera and screen based motion games. With our AI team, we implemented a powerful solution to transfer human movement to the in-game character",
    thumbnail: caseStudyAiThumbnail,
  }

  const gameDev = {
    title: "Game development process",
    description:
      "One of the first stages of game development by our team is to define the purpose and storyline of the game. We also determine who is to be the target recipient of the production and what they expect from the game. We work in agile management methodologies so as to be ready for changes.",
    thumbnail: caseStudyGameDevThumbnail,
  }

  const caseStudies = [aiCamera, gameDev, aiCamera, gameDev]
  var settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <Container id="case-studies">
      <Content>
        <Header>Case study</Header>
        <SliderWrapper>
          <Slider {...settings}>
            {caseStudies.map((caseStudy, index) => (
              <HomeCaseStudyElement
                key={index}
                thumbnail={caseStudy.thumbnail}
                title={caseStudy.title}
                description={caseStudy.description}
              />
            ))}
          </Slider>
        </SliderWrapper>
      </Content>
    </Container>
  )
}
