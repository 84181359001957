import styled from "styled-components"

export const Container = styled.div`
  padding: 35px 26px 40px;
`

export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`

export const Description = styled.p`
  margin-top: 20px;
`
