import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 4%;
  max-height: 450px;
  padding: 35px 40px 50px;
  @media (max-width: 890px) {
    max-height: initial;
    padding: 35px 0 18px;
    display: flex;
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  height: 100%;
  justify-self: end;
`

export const Button = styled.a`
  padding: 14px 25px;
  border-radius: 60px;
  transform: translateY(0px);
  transition: transform 0.3s ease-out;
  color: #fff;
  background-color: #232125;
  box-shadow: 5px 10px 30px 4px #888888;
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.3s ease-out;
  }
`

export const TextWrapper = styled.div`
  justify-self: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 600px;
  gap: 28px;
  padding: 20px 0;
  @media (max-width: 890px) {
    gap: 20px;
    align-items: center;
  }
`
